import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/database';
import 'firebase/firestore';
// import 'firebase/messaging';
// import 'firebase/storage';


const config = {
    apiKey: "AIzaSyDzJu7vQgLs563vC1x_RLAbcBdM-MfY_tY",
    authDomain: "myraceads.firebaseapp.com",
    databaseURL: "https://myraceads.firebaseio.com",
    projectId: "myraceads",
    storageBucket: "myraceads.appspot.com",
    messagingSenderId: "249687817768",
    appId: "1:249687817768:web:9694ad13b23d6ef0827f1d"
};

firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();
// export const storage = firebase.storage();

export default firebase;