import React, { createContext, useState, useEffect } from 'react';
// import firebase from './config/firebase'

export const IncomingStateContext = createContext(null);

const IncomingState = (props) => {
    const getterSetter = useState(0);
    // const [incomingState, setIncomingState] = useState(0);
    
    //useEffect(() => firebase.IncomingState().onIncomingStateStateChanged(setUser));

    return (
        <IncomingStateContext.Provider value={getterSetter}>
            {props.children}
        </IncomingStateContext.Provider>
    )
}

export default IncomingState;