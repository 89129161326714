import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Auth from './Auth';
import IncomingState from './IncomingState';

(function(){

  // const script = window.document.querySelector('script[src*=myraceads]');
  // const script = window.document.querySelector('script[src*=hosting.myrace.com]');
  const script = window.document.querySelector('script[src*=myrace]');
  if (!script) {
    console.log('No Script Src!!! Looked in window.document.querySelector(script[src*=myrace]')
    console.log('using local');
    
  } else {
    console.log('script.src = ',script.src)
  }

  var url = new URL(script ? script.src : 'http://localhost?a=local');
  var orgKey = url.searchParams.get('a');

  function orgKeyValid(orgKey){
    return !!orgKey;
  }

  if(orgKeyValid(orgKey)) {
  
    const rootEl = window.document.createElement('div');
    window.document.body.appendChild(rootEl);
  
    ReactDOM.render(
      <React.StrictMode>
        <Auth>
          <IncomingState>
            <App orgKey={orgKey} />
          </IncomingState>
        </Auth>
      </React.StrictMode>,
      rootEl
    );

  }

})();