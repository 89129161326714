import React, { useEffect, useContext } from 'react';
import './App.css';
import Airship from './AirshipPreview/AirshipFramer';
import { AuthContext } from './Auth';
import { auth, db } from './config/firebase';
import { firestore } from 'firebase';
import {IncomingStateContext} from './IncomingState';

function sendEvent(orgKey, uid, type, payload){

  if(type==='pageview') {
    db.collection('orgKeys').doc(orgKey).collection('events').add({
      orgKey, uid,
      type,
      url: payload,
      timestamp: firestore.FieldValue.serverTimestamp()
    });
  } else if(type==='click') {
    db.collection('orgKeys').doc(orgKey).collection('events').add({
      orgKey, uid,
      type,
      timestamp: firestore.FieldValue.serverTimestamp(),
      ...payload
    });
  }
  
  
}


function startListening(orgKey, uid, [incomingState, setIncomingState]){

  console.log('startListening orgKey:', orgKey)

  const { href } = window.location;
  sendEvent(orgKey, uid, 'pageview', href);
  

  function clickListener(e){

    console.log('CLICK', e.target);
    const { id, className, nodeName } = e.target;
    
    let save = {id, className, nodeName};
  
    if(nodeName==='BUTTON'){
      save.text = e.target.innerText;
    }
    sendEvent(orgKey, uid, 'click', save)
  }
  
  window.document.addEventListener('click', clickListener, false);
  
  const unsub = db.collection('users').doc(uid).onSnapshot(snap => {
    console.log(`Received doc snapshot: ${snap}`, snap.data());
    setIncomingState(snap.data());
  }, err => {
    console.log(`Encountered error: ${err}`);
  });

  function stopListening(){
    window.document.removeEventListener('click', clickListener, false);
    unsub();
  }
  return stopListening;
}




function useListener(orgKey){
  const user = useContext(AuthContext);
  const incomingStateGetterSetter = useContext(IncomingStateContext);
  useEffect(()=>{
    if(user){
      console.log('useListener UID:', user.uid);
      console.log('useListener orgKey:', orgKey);
      return startListening(orgKey, user.uid, incomingStateGetterSetter);
    }
  }, [user&&user.uid]);
}








function App({orgKey}) {
  const user = useContext(AuthContext);
  
  useEffect(()=>{
    auth.signInAnonymously().catch((error) => {
      console.log('Could not auth user:', error);
    });
  },[]);
  useListener(orgKey);



  return (

      <div className="App">
        <Airship />
      </div>

  );
}

export default App;
