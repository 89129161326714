import imgRunBetter from './images/adsImages/RunBetter.png'
import imgGarmin from './images/adsImages/garmin.png'
import imgGu from './images/adsImages/gu.png'
import imgRevuLogo from './images/adsImages/revolugo.png'
import imgCapitalOne from './images/adsImages/2_0001sCapital-One.png'
import imgMyRace from './images/adsImages/logo-07.svg'

import craft1 from './images/craft-01.svg'
import craft2 from './images/craft-02.svg'
import craft3 from './images/craft-03.svg'
import craft4 from './images/craft-04.svg'
import craft5 from './images/craft-05.svg'
import craft6 from './images/craft-06.svg'
import craft7 from './images/craft-07.svg'
import craft8 from './images/craft-08.svg'
import craft9 from './images/craft-09.svg'
import craft10 from './images/craft-10.svg'
import craft11 from './images/craft-11.svg'
export const crafts = {
    '01': {
        img: craft1
    },
    '02': {
        img: craft2
    },
    '03': {
        img: craft3
    },
    '04': {
        img: craft4
    },
    '05': {
        img: craft5
    },
    '06': {
        img: craft6
    },
    '07': {
        img: craft7
    },
    '08': {
        img: craft8
    },
    '09': {
        img: craft9
    },
    '10': {
        img: craft10
    },
    '11': {
        img: craft11
    },
};
export const craftsArr = Object.entries(crafts);



export const logos = {
    '01': {
        img: imgRunBetter
    },
    '02': {
        img: imgGarmin
    },
    '03': {
        img: imgGu
    },
    '04': {
        img: imgRevuLogo
    },
    '05': {
        img: imgCapitalOne
    },
    '06': {
        img: imgMyRace
    },
}

export const logosArr = Object.entries(logos);